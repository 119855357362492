<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="pse.project_state"
      :options="states"
      title="Project State"
      name="project_state"
      track-by="id"
      label="name"
      placeholder=""
      required
    />
    <BaseSelect
      v-model="pse.email_template"
      :options="templates"
      title="Email Template"
      name="email_template"
      track-by="id"
      label="name"
      placeholder=""
      required
    />
    <BaseSelect
      v-model="pse.usr_roles"
      :options="roles"
      title="Roles"
      name="usr_roles"
      track-by="id"
      label="name"
      placeholder="Select Roles"
      multiple
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View for editing/creating an Email notification (project state email).
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    // group for notification
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      pse: {
        id: 0,
        email_template: '',
        project_state: '',
        usr_roles: '',
      },
      error: '',
      roles: [],
      states: [],
      templates: [],
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create Email Notification'
      }
      return 'Update Email Notification'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      this.error = ''
      groupAPI.createProjectStateEmail(this.groupId, this.pse).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      this.error = ''
      groupAPI.updateProjectStateEmail(this.groupId, this.pse).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      const requests = [
        groupAPI.getGroupRoles(this.groupId),
        groupAPI.getProjectStates(this.groupId),
        groupAPI.getEmailTemplates(this.groupId),
      ]
      if (this.id) {
        requests.push(groupAPI.getProjectStateEmail(this.groupId, this.id))
      }
      Promise.all(requests).then(([roles, states, templates, pse]) => {
        this.roles = roles.data
        this.states = states.data
        this.templates = templates.data
        if (pse && pse.data) {
          this.pse = pse.data
        }
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
