<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Milestone Groups"
  />
</template>

<script>
/**
 * View to list all Milestone groups for a group.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      fields: [
        {
          name: 'name',
          title: 'Name',
          linkToIndex: true,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.milestoneGroupsURL(this.groupId)
    },
  },
}
</script>
