<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Service Attachments"
  />
</template>

<script>
/**
 * View to list Service Icons.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'attachment_type_id',
          title: 'Attachment ID',
          isIndex: true,
          visible: false,
        },
        {
          name: 'attachment_type_name',
          title: 'Attachment',
        },
        {
          name: 'project_states',
          formatter: this.listToString,
          title: 'States',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.serviceAttachmentsURL(this.serviceId)
    },
  },
  methods: {
    listToString (l) {
      return l.join(', ')
    },
  },
}
</script>
