<template>
  <BaseFormCard
    :heading="submitName + ' Milestone'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-if="!id"
      v-model="milestone_type"
      :options="milestoneTypes"
      title="Type"
      name="type"
      required
    />
    <BaseInput
      v-if="!!id || milestone_type !== 'Attachment'"
      v-model="name"
      :maxlength="32"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseSelect
      v-if="!id && milestone_type === 'Attachment'"
      v-model="attachment_type"
      :options="attachmentTypes"
      title="Attachment"
      name="attachment_type"
      track-by="id"
      label="name"
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Modal to create or update a group milestone
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      milestone_type: 'CheckBox',
      milestoneTypes: ['CheckBox', 'Date', 'Text', 'Attachment'],
      name: '',
      attachment_type: '',
      attachmentTypes: [],
      projectStates: [],
      requiredStates: [],
      defaultRequiredStates: [],
      resetStates: [],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (this.id) {
        return 'Update'
      }
      return 'Create'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      const requests = [
        groupAPI.getAttachmentTypes(this.groupId),
        groupAPI.getProjectStates(this.groupId),
      ]
      if (this.id) {
        requests.push(groupAPI.getMilestone(this.groupId, this.id))
      }
      Promise.all(requests).then(([attachmentTypes, projectStates, milestone]) => {
        this.attachmentTypes = attachmentTypes.data.filter(t => t.is_user_type)
        this.projectStates = projectStates.data.map(s => s.name)
        this.defaultRequiredStates = projectStates.data.filter(s => s.milestone_required).map(s => s.name)
        this.requiredStates = this.defaultRequiredStates.slice()
        if (this.id) {
          this.name = milestone.data.name
          this.resetStates = milestone.data.reset_states.slice()
          const requiredStates = milestone.data.required_states.slice()
          // override default required states if set
          if (requiredStates && requiredStates.length > 0) {
            this.requiredStates = requiredStates
          }
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      const milestone = {
        name: this.name,
        required_states: this.requiredStates,
        reset_states: this.resetStates,
      }
      if (this.milestone_type === 'CheckBox') {
        milestone.is_check = true
      } else if (this.milestone_type === 'Date') {
        milestone.is_date = true
      } else if (this.milestone_type === 'Text') {
        milestone.is_text = true
      } else if (this.milestone_type === 'Attachment' && this.attachment_type) {
        milestone.attachment_type_id = this.attachment_type.id
        milestone.name = this.attachment_type.name + ' Provided'
      } else {
        return
      }
      groupAPI.createMilestone(this.groupId, milestone).then(resp => {
        this.$parent.$toasted.success('Milestone created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      const milestone = {
        id: parseFloat(this.id),
        name: this.name,
        required_states: this.requiredStates,
        reset_states: this.resetStates,
      }
      groupAPI.updateMilestone(this.groupId, milestone).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
