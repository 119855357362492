<template>
  <BaseFormCard
    :heading="submitName + ' Service Icon'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInputFile
      v-model="file"
      title="File"
      name="file"
      type="file"
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      file: null,
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  methods: {
    create () {
      groupAPI.createServiceIcon(this.groupId, this.file).then(resp => {
        this.$router.push({ name: 'service_icon_list' })
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      groupAPI.updateServiceIcon(this.groupId, this.id, this.file).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
  },
}
</script>
