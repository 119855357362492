<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0;"
  >
    <ListTemplate
      ref="listTemplate"
      :fields="fields"
      :api-url="apiURL"
      title="Attachment Types"
      default-sort="ord,name"
    >
      <template #template_filename="props">
        <a
          v-if="props.rowData.template_filename"
          :href="downloadLink(props.rowData)"
        >{{ props.rowData.template_filename }}</a>
      </template>
      <template #actions="props">
        <button
          v-if="props.rowData.template_filename"
          class="btn btn-icon btn-primary"
          title="Delete Template File"
          @click.prevent="confirmDelete(props.rowData)"
        >
          <FileRemoveIcon title="Delete Template File" />
        </button>
        <button
          v-else
          class="btn btn-icon btn-primary"
          title="Upload Template File"
          @click.prevent="createTemplate(props.rowData)"
        >
          <FileUploadIcon title="Upload Template File" />
        </button>
      </template>
    </ListTemplate>
    <FieldError v-model="error" />
    <ConfirmDialog
      ref="dialog"
      @confirmed="deleteTemplate"
    />
    <AttachmentTypeTemplateCreate
      ref="templateCreate"
      @created="refreshTable"
    />
  </div>
</template>

<script>
/**
 * List view for Attachment Types for a Group
 */
import FileUploadIcon from 'vue-material-design-icons/FileUpload.vue'
import FileRemoveIcon from 'vue-material-design-icons/FileRemove.vue'
import ListTemplate from '@/components/ListTemplate'
import FieldError from '@/components/FieldError'
import ConfirmDialog from '@/components/ConfirmDialog'
import AttachmentTypeTemplateCreate from '@/views/Group/AttachmentTypeTemplateCreate'
import groupAPI from '@/api/group'

export default {
  components: {
    AttachmentTypeTemplateCreate,
    ConfirmDialog,
    FieldError,
    FileUploadIcon,
    FileRemoveIcon,
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'is_financial',
          title: 'Is Financial',
          formatter: this.yesNo,
        },
        {
          name: 'template_url',
          title: 'Template URL',
        },
        {
          name: 'template_filename',
          title: 'Template File',
        },
        {
          name: 'ord',
          title: 'Order',
          visible: false,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
      error: '',
    }
  },
  computed: {
    apiURL () {
      return groupAPI.attachmentTypesURL(this.groupId)
    },
  },
  methods: {
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
    downloadLink (rowData) {
      return process.env.VUE_APP_API_URL + groupAPI.attachmentTypeTemplateDownloadURL(rowData.id)
    },
    refreshTable () {
      this.$refs.listTemplate.refresh()
    },
    createTemplate (rowData) {
      this.$refs.templateCreate.show(rowData)
    },
    confirmDelete (rowData) {
      this.$refs.dialog.confirm(
        'Confirm Template Delete',
        'Are you sure you want to delete the template for ' + rowData.name + '?',
        rowData,
      )
    },
    deleteTemplate (at) {
      groupAPI.deleteAttachmentTypeTemplate(this.groupId, at.id).then(resp => {
        this.error = ''
        this.refreshTable()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
