<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Service Icons"
  >
    <template #data="props">
      <img
        :src="iconURL(props.rowData.id)"
        width="42"
        class="mr-3 bg-white rounded-pill"
      >
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to list Service Icons.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'filename',
          title: 'Filename',
        },
        {
          name: 'data',
          title: 'Icon',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.serviceIconsURL(this.groupId)
    },
  },
  methods: {
    iconURL (id) {
      return '/api' + groupAPI.serviceIconURL(this.groupId, id)
    },
  },
}
</script>
