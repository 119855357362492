<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="State Transitions"
  />
</template>

<script>
/**
 * View to list all Project Permissions for a Group
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'src_state.name',
          title: 'Source State',
          sortField: 'src_state.name',
          filterType: String,
          filterOptions: groupAPI.getStateNames,
        },
        {
          name: 'dst_state.name',
          title: 'Destination State',
          sortField: 'dst_state.name',
          filterType: String,
          filterOptions: groupAPI.getStateNames,
        },
        {
          name: 'comment_required',
          title: 'Comment Required',
          formatter: this.yesNo,
        },
        {
          name: 'usr_roles',
          title: 'Roles',
          formatter: this.roleLabels,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.transitionsURL(this.groupId)
    },
  },
  methods: {
    roleLabels (roles) {
      let rs = ''
      let labelclass = ''
      roles.forEach(function (role) {
        if (role.name === 'Admin') {
          labelclass = 'badge badge-danger'
        } else if (role.name === 'Report') {
          labelclass = 'badge badge-success'
        } else if (role.name === 'Queue') {
          labelclass = 'badge badge-info'
        } else {
          labelclass = 'badge badge-secondary'
        }
        rs = rs + ' <span class="' + labelclass + '">' + role.name + '</span>'
      })
      return rs
    },
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
  },
}
</script>
