<template>
  <Modal
    ref="modal"
    title="Attachment Type Template Create"
  >
    <BaseForm
      submit-title="Upload"
      :submit-promise="submit"
      @cancel="cancel"
    >
      <BaseInputFile
        v-model="file"
        title="File"
        name="file"
        type="file"
        required
      />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to attach a file to a project.
 * Emit's a 'file-upload' event when successful.
 */
import Modal from '@/components/Modal'
import groupAPI from '@/api/group'

export default {
  components: {
    Modal,
  },
  data () {
    return {
      file: null,
      attachment_type: '',
      attachment_type_name: '',
    }
  },
  methods: {
    reset () {
      this.file = null
      this.attachment_type = ''
      this.attachment_type_name = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    show (at) {
      this.reset()
      this.attachment_type = at
      this.$refs.modal.show()
    },
    async submit () {
      const groupID = this.attachment_type.project_type_id
      const atID = this.attachment_type.id
      await groupAPI.uploadAttachmentTypeTemplate(groupID, atID, this.file)
      this.$emit('created')
      this.cancel()
    },
  },
}
</script>
