<template>
  <ListTemplate
    :fields="fields"
    :create-action="false"
    :update-action="isAdmin"
    :delete-action="false"
    :api-url="apiURL"
    :title="$route.meta.title"
  />
</template>

<script>
/**
 * View to list all groups.
 */
import ListTemplate from '@/components/ListTemplate'
import { mapGetters } from 'vuex'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  data () {
    return {
      fields: [
        {
          name: 'name',
          title: 'Name',
          linkTo: this.groupLink,
        },
        {
          name: 'descr',
          title: 'Description',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.groupsURL()
    },
    ...mapGetters('user', ['isAdmin', 'isGroupAdmin']),
  },
  methods: {
    groupLink (group) {
      if (this.isAdmin || this.isGroupAdmin(group.id)) {
        return { name: 'milestones', params: { groupId: group.id } }
      }
      return ''
    },
  },
}
</script>
