<template>
  <BaseFormCard
    :heading="submitName + ' Rule'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="rule.project_state"
      :options="states"
      title="State"
      name="state"
      track-by="id"
      label="name"
    />
    <BaseSelect
      v-model="rule.requester_role"
      :options="roles"
      title="Requester"
      name="requester"
      track-by="id"
      label="name"
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Modal view for editing/creating a project role rule.
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    /** group for project role */
    groupId: {
      type: [String, Number],
      required: true,
    },
    /** project role  */
    roleId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      rule: {
        id: 0,
        usr_role: null,
        project_state: null,
        requester_role: null,
      },
      roles: [],
      states: [],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      this.error = ''
      groupAPI.createProjectRoleRule(this.groupId, this.roleId, this.rule).then(resp => {
        this.$parent.$toasted.success('Rule created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      this.error = ''
      groupAPI.updateProjectRoleRule(this.groupId, this.roleId, this.rule).then(resp => {
        this.$parent.$toasted.success('Rule updated')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      Promise.all([
        groupAPI.getGroupRoles(this.groupId),
        groupAPI.getProjectStates(this.groupId),
      ]).then(([roles, states]) => {
        this.roles = roles.data
        this.states = states.data
      }).catch(error => {
        this.error = error.message
      })
      if (this.id) {
        groupAPI.getProjectRoleRule(this.groupId, this.roleId, this.id).then(resp => {
          this.rule = resp.data
        }).catch(error => {
          this.error = error.message
        })
      }
    },
  },
}
</script>
