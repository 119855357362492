<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    :update-action="false"
    title="Change Notifications"
  />
</template>

<script>
/**
 * View to show all Email Notifications (project change emails) for a Group.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'method',
          title: 'Method',
        },
        {
          name: 'resource',
          title: 'Resource',
        },
        {
          name: 'email_template.name',
          sortField: 'email_template.name',
          title: 'Email Template',
        },
        {
          name: 'usr_role.name',
          title: 'User Role',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.projectChangeEmailsURL(this.groupId)
    },
  },
}
</script>
