<template>
  <BaseFormCard
    :heading="submitName + ' Property Option'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="option.property"
      :options="properties"
      :readonly="!!id"
      title="Property"
      name="property"
      track-by="id"
      label="name"
      required
    />
    <BaseInput
      v-model="option.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="option.title"
      title="Title"
      name="title"
      type="text"
      placeholder="Title"
      required
      trim
    />
    <BaseInputNumber
      v-model.number="option.ord"
      title="Order"
      name="ord"
      placeholder="Order"
      required
      :min="0"
    />
    <BaseInputNumber
      v-model.number="option.sla_hours"
      title="SLA Hours"
      name="sla_hours"
      :min="1"
    />
    <BaseInputCheckbox
      v-model="option.disabled"
      title="Disabled"
      name="disabled"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to create a new Property Option.
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      properties: [],
      option: {
        name: '',
        title: '',
        ord: '',
        property: '',
        disabled: false,
        sla_hours: null,
      },
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    if (this.id) {
      this.getPropertyOption()
    } else {
      this.getProperties()
    }
  },
  methods: {
    getProperties () {
      groupAPI.getProperties(this.groupId).then(resp => {
        this.properties = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    getPropertyOption () {
      groupAPI.getPropertyOption(this.groupId, this.id).then(resp => {
        this.option = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    create () {
      const option = this.option
      groupAPI.createPropertyOption(this.groupId, option).then(resp => {
        this.$parent.$toasted.success('Option created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      const option = this.option
      groupAPI.updatePropertyOption(this.groupId, option).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
  },
}
</script>
