<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Email Notifications"
  />
</template>

<script>
/**
 * View to show all Email Notifications (project state emails) for a Group.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'project_state.name',
          sortField: 'project_state.name',
          title: 'Project State',
        },
        {
          name: 'email_template.name',
          sortField: 'email_template.name',
          title: 'Email Template',
        },
        {
          name: 'email_template.template_type',
          sortField: 'email_template.template_type',
          title: 'Type',
        },
        {
          name: 'usr_roles',
          title: 'Roles',
          formatter: this.roleLabels,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.projectStateEmailsURL(this.groupId)
    },
  },
  methods: {
    roleLabels (roles) {
      let rs = ''
      let labelclass = ''
      roles.forEach(function (role) {
        if (role.name === 'Admin') {
          labelclass = 'badge badge-danger'
        } else if (role.name === 'Report') {
          labelclass = 'badge badge-success'
        } else if (role.name === 'Queue') {
          labelclass = 'badge badge-info'
        } else {
          labelclass = 'badge badge-secondary'
        }
        rs = rs + ' <span class="' + labelclass + '">' + role.name + '</span>'
      })
      return rs
    },
  },
}
</script>
