<template>
  <BaseFormCard
    :heading="submitName + ' State Transition'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="transition.name"
      :maxlength="64"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      trim
    />
    <BaseSelect
      v-model="transition.src_state"
      :options="states"
      title="Source"
      name="src_state"
      track-by="id"
      label="name"
      required
    />
    <BaseSelect
      v-model="transition.dst_state"
      :options="states"
      title="Destination"
      name="dst_state"
      track-by="id"
      label="name"
      required
    />
    <BaseInputCheckbox
      v-model="transition.comment_required"
      title="Comment Required"
      name="comment_required"
    />
    <BaseSelect
      v-model="transition.usr_roles"
      :options="roles"
      title="Roles"
      name="usr_roles"
      track-by="id"
      label="name"
      placeholder="Select Roles"
      multiple
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Modal view for editing/creating an Email notification (project state email).
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    // group for notification
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      transition: {
        id: 0,
        name: '',
        src_state: '',
        dst_state: '',
        usr_roles: '',
        comment_required: false,
      },
      error: '',
      roles: [],
      states: [],
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      this.error = ''
      groupAPI.createTransition(this.groupId, this.transition).then(resp => {
        this.$parent.$toasted.success('State Transition created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      this.error = ''
      groupAPI.updateTransition(this.groupId, this.transition).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      const requests = [
        groupAPI.getGroupRoles(this.groupId),
        groupAPI.getProjectStates(this.groupId),
      ]
      if (this.id) {
        requests.push(groupAPI.getTransition(this.groupId, this.id))
      }
      Promise.all(requests).then(([roles, states, transition]) => {
        this.roles = roles.data
        this.states = states.data
        if (transition && transition.data) {
          this.transition = transition.data
        }
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
