<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Booking Subtypes"
    :create-action="false"
    :delete-action="false"
  />
</template>

<script>
import ListTemplate from '@/components/ListTemplate'
import bookingAPI from '@/api/booking'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'booking_type.name',
          title: 'Booking Type',
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'reviewer_role',
          title: 'Reviewer Role',
          formatter: this.getName,
        },
        {
          name: 'approver_role',
          title: 'Approver Role',
          formatter: this.getName,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return bookingAPI.bookingSubtypesURL(this.groupId)
    },
  },
  methods: {
    getName (obj) {
      if (obj) {
        return obj.name
      }
      return '*'
    },
  },
}
</script>
