<template>
  <div>
    <table class="template-table">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{{ template.name }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{{ template.template_type }}</td>
        </tr>
        <tr>
          <td>Subject</td>
          <td>
            <pre>{{ template.subject }}</pre>
          </td>
        </tr>
        <tr>
          <td>Body</td>
          <td>
            <pre>{{ template.body }}</pre>
          </td>
        </tr>
      </tbody>
    </table>
    <router-link
      :to="{ name: 'update_email_template' }"
      class="btn btn-primary"
      tag="button"
    >
      Edit
    </router-link>
    <button
      class="btn btn-default"
      @click.prevent="$router.go(-1)"
    >
      Back
    </button>
  </div>
</template>

<script>
/**
 * View to display an email template
 */
import groupAPI from '@/api/group'

export default {
  props: {
    // group for template
    groupId: {
      type: [String, Number],
      required: true,
    },
    // template id
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      template: {
        name: '',
        subject: '',
        body: '',
      },
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      groupAPI.getEmailTemplate(this.groupId, this.id).then(resp => {
        this.template = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>

<style scoped>
.template-table {
  margin-bottom: 10px;
}
.template-table td {
  padding: 5px 20px 0px 0px;
}
.template-table tr td:nth-child(1) {
  font-weight: bold;
}
</style>
