<template>
  <BaseFormCard
    :submit-title="submitName"
    @submit="saveAttachmentType"
  >
    <BaseInput
      v-model="attachmentType.name"
      :maxlength="32"
      title="Attachment Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="attachmentType.template_url"
      :maxlength="32"
      title="Template URL"
      name="template_url"
      type="url"
      trim
    />
    <BaseInputCheckbox
      v-model="attachmentType.is_financial"
      title="Is Financial"
      name="is_financial"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit or create an attachment type for a group
 */
import groupAPI from '@/api/group'
import FieldError from '@/components/FieldError'

export default {
  components: {
    FieldError,
  },
  props: {
    /** group for attachment type */
    groupId: {
      type: [String, Number],
      required: true,
    },
    /** if set, edit this attachment type */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      name: '',
      attachmentType: {
        id: 0,
        name: '',
        ord: 1,
        template_url: null,
        is_user_type: true,
        is_financial: false,
      },
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id || !this.attachmentType.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    saveAttachmentType () {
      if (this.attachmentType.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      groupAPI.createAttachmentType(this.groupId, this.attachmentType).then(resp => {
        this.$router.push({ name: 'attachment_types' })
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      groupAPI.updateAttachmentType(this.attachmentType).then(resp => {
        this.$router.push({ name: 'attachment_types' })
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      if (this.id) {
        groupAPI.getAttachmentType(this.id).then(resp => {
          this.attachmentType = resp.data
        }).catch(error => {
          this.error = error.message
        })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
