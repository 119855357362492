<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="attach.attachment_type_id"
      :options="attachmentTypes"
      title="Attachment Type"
      name="attachment_type"
      placeholder="Attachment"
      bind-by="id"
      label="name"
      :disabled="Boolean(attach.attachment_type_id)"
      required
      @input="getServiceAttachment"
    />
    <BaseSelect
      v-model="attach.project_states"
      :options="projectStates"
      title="States"
      name="states"
      placeholder="States"
      multiple
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit or update an email template for a group
*/
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    serviceId: {
      type: [String, Number],
      required: true,
    },
    /** if set, email template to edit */
    attachmentTypeId: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      attach: {
        attachment_type_id: '',
        project_states: [],
      },
      attachmentTypes: [],
      projectStates: [],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.attachmentTypeId) {
        return 'Create Service Attachment'
      }
      return 'Update Service Attachment'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      this.updateServiceAttachment()
    },
    updateServiceAttachment () {
      this.error = ''
      groupAPI.updateServiceAttachment(this.serviceId, this.attach.attachment_type_id, this.attach.project_states).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      const requests = [
        groupAPI.getProjectStates(this.groupId),
        groupAPI.getAttachmentTypes(this.groupId),
      ]
      if (this.attachmentTypeId) {
        requests.push(groupAPI.getServiceAttachment(this.serviceId, this.attachmentTypeId))
      }
      Promise.all(requests).then(([states, atTypes, attach]) => {
        this.projectStates = states.data.map(s => s.name)
        this.attachmentTypes = atTypes.data
        if (attach && attach.data) {
          this.attach = attach.data
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    getServiceAttachment () {
      groupAPI.getServiceAttachment(this.serviceId, this.attach.attachment_type_id).then(attach => {
        this.attach = attach.data
      }).catch(error => {
        if (error.response && error.response.status === 404) {
          this.attach.project_states = []
        } else {
          this.error = error.message
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
