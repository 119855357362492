<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Service Groups"
    default-sort="name"
  />
</template>

<script>
/**
 * View to list Service Groups.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'name',
          title: 'Name',
          linkTo: (sg) => { return { name: 'service_list', query: { 'service_group.name_like': sg.name } } },
          dataClass: 'text-nowrap',
          sortField: 'name',
          filterType: String,
          filterField: 'name_like',
        },
        {
          name: 'descr',
          title: 'Description',
        },
        {
          name: 'max',
          title: 'Maximum Count',
        },
        {
          name: 'platform',
          title: 'Platform',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.serviceGroupsURL(this.groupId)
    },
  },
}
</script>
