<template>
  <BaseFormCard
    :heading="submitName + ' Service'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="service.service_group_id"
      :options="serviceGroups"
      name="service_group"
      title="Service Group"
      placeholder="Select Group"
      bind-by="id"
      label="name"
    />
    <BaseInput
      v-model="service.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="service.descr"
      title="Description"
      name="descr"
      type="text"
      placeholder="Description"
      required
      trim
    />
    <BaseInputPrice
      v-model.number="service.price"
      title="Default Price"
      name="price"
    />
    <div>
      <label>Unit Pricing</label>
      <table class="w-100 mb-3">
        <thead>
          <tr class="mb-3">
            <th>Minimum Count</th>
            <th>Price</th>
            <th />
          </tr>
        </thead>
        <tbody class="p-2">
          <tr
            v-for="(p, i) in service.prices"
            :key="p.min"
          >
            <td>{{ p.min }}</td>
            <td>$ {{ p.price }}</td>
            <td>
              <button
                class="btn btn-icon btn-primary"
                title="Delete"
                @click.prevent="deletePrice(i)"
              >
                <TrashIcon title="Delete" />
              </button>
            </td>
          </tr>
          <tr>
            <td class="pr-2">
              <BaseInputNumber
                v-model.number="count"
                class="mb-0"
                name="count"
              />
            </td>
            <td class="pr-2">
              <BaseInputPrice
                v-model.number="price"
                class="mb-0"
                name="unitPrice"
              />
            </td>
            <td>
              <button
                class="btn btn-icon btn-primary"
                title="Save"
                @click.prevent="addPrice(count, price)"
              >
                <PlusIcon title="Delete" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <BaseInputNumber
      v-model.number="service.slo"
      title="SLO (days)"
      name="slo"
    />
    <BaseInputNumber
      v-model.number="service.max"
      title="Maximum Count"
      name="max"
    />
    <BaseInput
      v-model="service.colour"
      title="Colour"
      name="colour"
      pattern="^#([0-9a-fA-F]{1,2}){3,4}$"
      trim
    />
    <BaseTextarea
      v-model="service.warning"
      title="Warning"
      name="warning"
      trim
    />
    <BaseTextarea
      v-model="service.info"
      title="Info"
      name="info"
      trim
    />
    <BaseTextarea
      v-model="service.notification"
      title="Notification"
      name="notification"
      trim
    />
    <BaseSelect
      v-model="service.notification_states"
      :options="projectStateNames"
      title="Notification States"
      name="notification_states"
      placeholder="States"
      :disabled="!service.notification"
      multiple
    />
    <BaseSelect
      v-model="service.service_icon_id"
      :options="serviceIcons"
      title="Icon"
      name="service_icon"
      bind-by="id"
      label="filename"
    />
    <BaseSelect
      v-model="service.team_id"
      :options="teams"
      title="Team"
      name="team"
      bind-by="id"
      label="name"
    />
    <BaseInputCheckbox
      v-model="service.is_default"
      title="Is Default"
      name="is_default"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import TrashIcon from 'vue-material-design-icons/TrashCanOutline.vue'
import PlusIcon from 'vue-material-design-icons/Plus.vue'
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'
import teamAPI from '@/api/team'

export default {
  components: {
    FieldError,
    TrashIcon,
    PlusIcon,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      service: {
        service_group_id: null,
        service_icon_id: null,
        team_id: null,
        name: '',
        descr: '',
        warning: null,
        info: null,
        notification: null,
        notification_states: null,
        price: null,
        prices: null,
        slo: null,
        max: null,
        colour: null,
        is_default: false,
      },
      serviceIcons: [],
      serviceGroups: [],
      teams: [],
      projectStateNames: [],
      error: '',
      count: null,
      price: null,
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      const promises = [
        groupAPI.getServiceGroups(this.groupId),
        groupAPI.getServiceIcons(this.groupId),
        teamAPI.getTeams(),
        groupAPI.getProjectStates(this.groupId),
      ]
      if (this.id) {
        promises.push(groupAPI.getService(this.groupId, this.id))
      }
      Promise.all(promises).then(([serviceGroups, serviceIcons, teams, projectStates, service]) => {
        this.serviceIcons = serviceIcons.data
        this.serviceGroups = serviceGroups.data
        this.teams = teams.data
        this.projectStateNames = projectStates.data.map(ps => ps.name)
        if (this.id) {
          this.service = service.data
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    create () {
      groupAPI.createService(this.groupId, this.service).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      groupAPI.updateService(this.groupId, this.service).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    deletePrice (i) {
      this.service.prices.splice(i, 1)
    },
    addPrice (count, price) {
      if (count <= 0 || price < 0 || price === null || price === undefined) {
        return
      }
      let prices
      if (!this.service.prices) {
        prices = [{ min: count, price: price }]
      } else {
        prices = this.service.prices.slice()
        const i = prices.findIndex(p => p.min === count)
        if (i >= 0) {
          prices[i].price = price
        } else {
          prices.push({ min: count, price: price })
          prices.sort((a, b) => { return a.min - b.min })
        }
      }
      this.$set(this.service, 'prices', prices)
      this.count = null
      this.price = null
    },
  },
}
</script>

<style lang="scss" scoped>
  table td,th {
    padding-bottom: 4px;
  }
</style>
