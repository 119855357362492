<template>
  <div v-if="role.id">
    <h4>Role: {{ role.name }}</h4>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    roleId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
    }
  },
  computed: {
    ...mapState('group', ['role']),
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.error = ''
      this.getRole([this.groupId, this.roleId]).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('group', ['getRole']),
  },
}
</script>
