<template>
  <BaseFormCard
    :heading="submitName + ' Property'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="property.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="property.title"
      title="Title"
      name="title"
      type="text"
      placeholder="Title"
      required
      trim
    />
    <BaseInputCheckbox
      v-model="property.log_changes"
      title="Log Changes"
      name="log_changes"
    />
    <BaseInputCheckbox
      v-model="property.is_required"
      title="Is Required (all states)"
      name="is_required"
    />
    <BaseSelect
      v-model="property.requiredStates"
      :options="states"
      title="Required States"
      name="requiredStates"
      :disabled="property.is_required"
      multiple
    />
    <BaseInputCheckbox
      v-model="property.has_many"
      title="Has Many"
      name="has_many"
    />
    <BaseInputCheckbox
      v-model="property.disabled"
      title="Disabled"
      name="disabled"
    />
    <BaseInputNumber
      v-model.number="property.ord"
      title="Ord"
      name="ord"
      required
    />
    <BaseTextarea
      v-model="schema"
      title="Schema"
      name="schema"
      :rows="10"
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to create a new Property.
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      property: {
        name: '',
        title: '',
        log_changes: false,
        is_required: false,
        has_many: false,
        disabled: false,
        ord: null,
        schema: null,
        requiredStates: [],
      },
      states: [],
      schema: '',
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    groupAPI.getProjectStates(this.groupId).then(resp => {
      if (resp.data) {
        this.states = resp.data.map(s => s.name)
      }
    }).catch(error => {
      this.error = error.message
    })

    if (this.id) {
      groupAPI.getProperty(this.groupId, this.id).then(resp => {
        this.property = resp.data
        if (this.property.schema) {
          this.schema = JSON.stringify(this.property.schema, null, 2)
        } else {
          this.schema = ''
        }
      }).catch(error => {
        this.error = error.message
      })
    }
  },
  methods: {
    saveSchema () {
      if (!this.schema) {
        this.property.schema = null
        return true
      }
      try {
        this.property.schema = JSON.parse(this.schema)
        return true
      } catch (ex) {
        this.error = ex.toString()
        return false
      }
    },
    create () {
      if (!this.saveSchema()) {
        return
      }
      groupAPI.createProperty(this.groupId, this.property).then(resp => {
        this.$parent.$toasted.success('Property created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      if (!this.saveSchema()) {
        return
      }
      groupAPI.updateProperty(this.groupId, this.property).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
  },
}
</script>
