<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Rules"
  />
</template>

<script>
/**
 * View to list all rules for a usr role that is a project only role.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    roleId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'project_state',
          title: 'Project State',
          formatter: this.getName,
        },
        {
          name: 'requester_role',
          title: 'Requester',
          formatter: this.getName,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.projectRoleRulesURL(this.groupId, this.roleId)
    },
  },
  methods: {
    getName (obj) {
      if (obj) {
        return obj.name
      }
      return '*'
    },
  },
}
</script>
