<template>
  <BaseCard>
    <BaseForm
      :submit-title="submitName"
      @submit="submit"
      @cancel="goBack"
    >
      <div class="row">
        <div class="col-md-6 col-xs-6">
          <BaseInput
            v-model="name"
            :maxlength="32"
            title="Name"
            name="name"
            type="text"
            placeholder="Name"
            required
            trim
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 col-xs-5">
          <label>Milestones</label>
        </div>
        <div class="col-md-2 col-xs-2 text-center">
          <ChevronRightIcon title="" />
        </div>
        <div class="col-md-5 col-xs-5">
          <label>Group Members</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 col-xs-5">
          <div class="form-group">
            <select
              v-model="selected_milestones"
              class="form-control"
              multiple
              size="10"
            >
              <option
                v-for="m in milestones"
                :key="m.id"
                :value="m"
              >
                {{ m.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 col-xs-2 text-center">
          <div class="btn-group-vertical">
            <button
              class="btn btn-primary"
              @click.prevent="addMilestone"
            >
              Add
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="deleteMilestone"
            >
              Delete
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="moveUp"
            >
              Up
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="moveDown"
            >
              Down
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="selectAll"
            >
              Select All
            </button>
          </div>
        </div>
        <div class="col-md-5 col-xs-5">
          <div class="form-group">
            <select
              v-model="assigned_index"
              class="form-control"
              size="10"
              name="milestones"
            >
              <option
                v-for="(m, index) in assigned_milestones"
                :key="m.id"
                :value="index"
              >
                {{ m.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <FieldError v-model="error" />
    </BaseForm>
  </BaseCard>
</template>

<script>
/**
 * View to create or update a milestone group
 */
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    ChevronRightIcon,
    FieldError,
  },
  props: {
    // group for which milstone group is being created/updated
    groupId: {
      type: [String, Number],
      required: true,
    },
    // optional milestone group if updating instead of creating a group
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      assigned_index: '',
      assigned_milestones: [],
      selected_milestones: [],
      milestones: [],
      name: '',
      error: '',
    }
  },
  computed: {
    submitName () {
      if (this.id) {
        return 'Update'
      }
      return 'Create'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      const requests = [groupAPI.getMilestones(this.groupId)]
      if (this.id) {
        requests.push(groupAPI.getMilestoneGroup(this.groupId, this.id))
      }
      Promise.all(requests).then(([milestones, milestoneGroup]) => {
        this.milestones = milestones.data
        if (milestoneGroup) {
          this.name = milestoneGroup.data.name
          if (milestoneGroup.data.milestones) {
            this.assigned_milestones = milestoneGroup.data.milestones
          }
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      const group = { name: this.name, milestones: this.assigned_milestones }
      groupAPI.createMilestoneGroup(this.groupId, group).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      const group = {
        id: parseInt(this.id),
        name: this.name,
        milestones: this.assigned_milestones,
      }
      groupAPI.updateMilestoneGroup(this.groupId, group).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    moveUp () {
      const i = this.assigned_index - 1
      if (i >= 0) {
        const am = this.assigned_milestones.splice(i, 2).reverse()
        this.assigned_milestones.splice(i, 0, ...am)
        this.assigned_index = i
      }
    },
    moveDown () {
      const i = this.assigned_index
      if (i < this.assigned_milestones.length - 1) {
        const am = this.assigned_milestones.splice(i, 2).reverse()
        this.assigned_milestones.splice(i, 0, ...am)
        this.assigned_index += 1
      }
    },
    selectAll () {
      this.selected_milestones = this.milestones
    },
    deleteMilestone () {
      this.$delete(this.assigned_milestones, this.assigned_index)
    },
    addMilestone () {
      for (const milestone of this.selected_milestones) {
        if (this.assigned_milestones.findIndex(m => m.id === milestone.id) < 0) {
          this.assigned_milestones.push(milestone)
        }
      }
      this.selected_milestones = []
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
