<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Name</label>
          <p class="form-control-plaintext">
            {{ milestone_group.name }}
          </p>
        </div>
        <div class="form-group">
          <label>Members</label>
        </div>
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Milestone</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="m in milestone_group.milestones"
              :key="m.id"
            >
              <td v-text="m.name" />
              <td v-if="m.is_check">
                CheckBox
              </td>
              <td v-else-if="m.is_date">
                Date
              </td>
              <td v-else-if="m.is_text">
                Text
              </td>
              <td v-else-if="m.attachment_type_id">
                Attachment
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="error"
          class="text-danger"
        >
          {{ error }}
        </p>
        <router-link
          v-if="milestone_group.id"
          :to="{ name: 'update_milestone_group', params: { id: milestone_group.id } }"
          class="btn btn-primary"
          tag="button"
        >
          Edit
        </router-link>
        <button
          class="btn btn-default"
          @click.prevent="goBack"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * View to display a Milestone group.
 */
import groupAPI from '@/api/group'

export default {
  props: {
    groupId: {
      type: [String, Number],
      default: '',
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
      required: true,
    },
  },
  data () {
    return {
      milestone_group: { id: '', milestones: [] },
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      groupAPI.getMilestoneGroup(this.groupId, this.id).then(resp => {
        this.milestone_group = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
