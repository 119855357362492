<template>
  <BaseFormCard
    heading="Update Booking Subtype"
    submit-title="Update"
    @submit="update"
  >
    <BaseInput
      v-model="subtype.booking_type.name"
      title="Booking Type"
      name="booking_type"
      readonly
    />
    <BaseInput
      v-model="subtype.name"
      title="Name"
      name="name"
      required
    />
    <BaseSelect
      v-model="subtype.reviewer_role"
      :options="roles"
      title="Reviewer Role"
      name="reviewer_role"
      track-by="id"
      label="name"
    />
    <BaseSelect
      v-model="subtype.approver_role"
      :options="roles"
      title="Approver Role"
      name="approver_role"
      track-by="id"
      label="name"
    />
    <BaseInputNumber
      v-model.number="subtype.alert_lead_days"
      title="Incomplete Alert Lead Time (days)"
      name="alert_lead_days"
      :min="0"
    />
    <BaseInputNumber
      v-model.number="subtype.cancel_lead_days"
      title="Incomplete Cancel Lead Time (days)"
      name="cancel_lead_days"
      :min="0"
    />
    <BaseTextarea
      v-model="subtype.schema"
      title="Schema"
      name="schema"
      :rows="10"
      required
    />
    <BaseInputCheckbox
      v-model="subtype.show_slot"
      title="Show Slot"
      name="show_slot"
    />
    <BaseInputCheckbox
      v-model="subtype.show_time"
      title="Show Time"
      name="show_time"
    />
    <BaseInput
      v-model="subtype.colour"
      title="Colour"
      name="colour"
      pattern="^#([0-9a-fA-F]{1,2}){3,4}$"
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit or create a Project subtype record for a group
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'
import bookingAPI from '@/api/booking'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      subtype: {
        id: 0,
        booking_type: { name: '' },
        name: '',
        reviewer_role: null,
        approver_role: null,
        alert_lead_days: null,
        cancel_lead_days: null,
        schema: '',
        show_slot: false,
        show_time: false,
        colour: null,
      },
      roles: [],
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    update () {
      this.error = ''
      const subtype = Object.assign({}, this.subtype)
      try {
        subtype.schema = JSON.parse(this.subtype.schema)
      } catch (ex) {
        this.error = ex
        return
      }
      bookingAPI.updateBookingSubtype(this.groupId, subtype).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      return Promise.all([
        bookingAPI.getBookingSubtype(this.id),
        groupAPI.getGroupRoles(this.groupId),
      ]).then(([subtype, roles]) => {
        this.subtype.id = subtype.data.id
        this.subtype.booking_type = subtype.data.booking_type
        this.subtype.name = subtype.data.name
        this.subtype.reviewer_role = subtype.data.reviewer_role
        this.subtype.approver_role = subtype.data.approver_role
        this.subtype.schema = JSON.stringify(subtype.data.schema, null, 2)
        this.subtype.alert_lead_days = subtype.data.alert_lead_days
        this.subtype.cancel_lead_days = subtype.data.cancel_lead_days
        this.subtype.show_slot = subtype.data.show_slot
        this.subtype.show_time = subtype.data.show_time
        this.subtype.colour = subtype.data.colour
        this.roles = roles.data
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
