<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Property Options"
    default-sort="property.name,ord,name"
  />
</template>

<script>
/**
 * View to list all Domains.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'property.name',
          title: 'Property',
          filterType: String,
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'title',
          title: 'Title',
        },
        {
          name: 'ord',
          title: 'Order',
        },
        {
          name: 'disabled',
          title: 'Disabled',
        },
        {
          name: 'sla_hours',
          title: 'SLA hours',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.propertyOptionsURL(this.groupId)
    },
  },
}
</script>
