<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0px;"
  >
    <h3>Delivery Group: {{ group.name }}</h3>
    <ul class="nav nav-tabs">
      <li
        v-if="devVersion"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'group_roles' }"
          :class="{ 'active': $route.name.includes('group_role') }"
          class="nav-link"
        >
          Roles
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'milestones' }"
          :class="{ 'active': $route.matched.some(r => r.name === 'milestones') }"
          class="nav-link"
        >
          Milestones
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'milestone_groups' }"
          :class="{ 'active': $route.matched.some(r => r.name && r.name.includes('milestone_group')) }"
          class="nav-link"
        >
          Milestone Groups
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'attachment_types' }"
          :class="{ 'active': $route.matched.some(r => r.name && r.name.includes('attachment_type')) }"
          class="nav-link"
        >
          Attachment Types
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'project_state_emails' }"
          :class="{ 'active': $route.matched.some(r => r.name === 'project_state_emails') }"
          class="nav-link"
        >
          Email Notifications
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'email_templates' }"
          :class="{ 'active': $route.matched.some(r => r.name && r.name.includes('email_template')) }"
          class="nav-link"
        >
          Email Templates
        </router-link>
      </li>
      <li
        v-if="devVersion"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'project_permissions' }"
          :class="{ 'active': $route.matched.some(r => r.name && r.name.includes('project_permission')) }"
          class="nav-link"
        >
          Project Permissions
        </router-link>
      </li>
    </ul>
    <router-view v-if="group.id" />
  </div>
</template>

<script>
/**
 * Tabbed menu view for a Group
 */
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    /** group */
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      devVersion: (process.env.VUE_APP_VERSION.indexOf('.') === -1),
      error: '',
    }
  },
  computed: {
    ...mapState('group', ['group']),
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.error = ''
      this.getGroup(this.groupId).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('group', ['getGroup']),
  },
}
</script>

<style scoped>
.nav-tabs {
  margin-bottom: 20px;
}
</style>
