<template>
  <BaseFormCard
    heading="Add User"
    submit-title="Add"
    @submit="submit"
  >
    <BaseSelect
      v-model="user"
      :options="users"
      title="User"
      name="user"
      track-by="id"
      label="name"
      placeholder="Select User"
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Modal view for assigning a user to a specific user role (create a project_usr_role entry).
 */
import FieldError from '@/components/FieldError'
import userAPI from '@/api/user'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    // group for role
    groupId: {
      type: [String, Number],
      required: true,
    },
    // role user to be assigned to. Cannot be a project role.
    roleId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      user: null,
      users: [],
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      userAPI.getUsers().then(users => {
        this.users = users.data
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      groupAPI.createGroupRoleUser(this.groupId, this.roleId, { usr_id: this.user.id }).then(resp => {
        this.$parent.$toasted.success('User ' + this.user.name + ' added')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
