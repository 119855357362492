<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseSelect
      v-model="permission.usr_role"
      :options="roles"
      title="User Role"
      name="usr_role"
      track-by="id"
      label="name"
    />
    <BaseSelect
      v-model="permission.project_state"
      :options="states"
      title="State"
      name="project_state"
      track-by="id"
      label="name"
    />
    <BaseSelect
      v-model="permission.method"
      :options="methods"
      title="Method"
      name="method"
      required
    />
    <BaseSelect
      v-model="permission.resource"
      :options="resources"
      title="Resource"
      name="resource"
      taggable
      required
      @tag="addResource"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit or create a Project Permission record for a group
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    /** group for project permission */
    groupId: {
      type: [String, Number],
      required: true,
    },
    /** Optional permission to update. Create new one if not defined */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      permission: {
        id: 0,
        usr_role: null,
        project_state: null,
        method: '',
        resource: '',
      },
      roles: [],
      states: [],
      methods: ['GET', 'PUT', 'DELETE', 'POST'],
      resources: ['aims', 'aim_rule_status', 'attachment', 'attachments', 'bookings', 'delivery_date', 'financials', 'milestone', 'milestones', 'project', 'platforms', 'quotes', 'requesting_group', 'roles', 'sites', 'team', 'timeline', 'update', 'subcategories', 'services', 'services_lock', 'decommission', 'available_services', 'pan_code', 'survey', 'on_hold'],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create Project Permission'
      }
      return 'Update Project Permission'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      if (this.permission.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      this.error = ''
      groupAPI.createProjectPermission(this.groupId, this.permission).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      this.error = ''
      groupAPI.updateProjectPermission(this.groupId, this.permission).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getDataPromise () {
      this.error = ''
      return Promise.all([
        groupAPI.getGroupRoles(this.groupId),
        groupAPI.getProjectStates(this.groupId),
      ]).then(([roles, states]) => {
        this.roles = roles.data
        this.states = states.data
      })
    },
    getData () {
      if (this.id) {
        groupAPI.getProjectPermission(this.groupId, this.id).then(resp => {
          this.permission = resp.data
        }).catch(error => {
          this.error = error.message
        })
      }
      this.getDataPromise().catch(error => {
        this.error = error.message
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    addResource (r) {
      this.resources.push(r)
      this.permission.resource = r
    },
  },
}
</script>
