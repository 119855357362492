<template>
  <ListTemplate
    :fields="fields"
    :update-action="isNotSystemRole"
    :delete-action="isNotSystemRole"
    :api-url="apiURL"
    title="Roles"
  />
</template>

<script>
/**
 * View to display all the user roles for a group.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      filterText: '',
      error: '',
      fields: [
        {
          name: 'name',
          title: 'Name',
          linkTo: this.roleLink,
        },
        {
          name: 'descr',
          title: 'Description',
        },
        {
          name: 'is_project_role',
          title: 'Project Role',
          formatter: this.yesNo,
        },
        {
          name: 'is_team_role',
          title: 'Team Role',
          formatter: this.yesNo,
        },
        {
          name: 'is_team_usr',
          title: 'Team User',
          rowFormatter: this.isTeamUsr,
        },
        {
          name: 'is_creator',
          title: 'Initialised with Project Creator',
          rowFormatter: this.isCreator,
        },
        {
          name: 'required_state.name',
          title: 'Required State',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
      systemRoles: ['Team Manager', 'Team Member'],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.groupRolesURL(this.groupId)
    },
  },
  methods: {
    isNotSystemRole (role) {
      return !this.systemRoles.includes(role.name)
    },
    roleLink (role) {
      if (this.isNotSystemRole(role)) {
        if (role.is_project_role) {
          return { name: 'group_role_rule_list', params: { roleId: role.id } }
        } else if (!role.is_team_role) {
          return { name: 'group_role_user_list', params: { roleId: role.id } }
        }
      }
      return ''
    },
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
    isTeamUsr (role) {
      return role.is_project_role ? this.yesNo(role.is_team_usr) : ''
    },
    isCreator (role) {
      return role.is_project_role ? this.yesNo(role.is_creator) : ''
    },
  },
}
</script>
