<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Properties"
    default-sort="name"
  />
</template>

<script>
/**
 * View to list all Domains.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'title',
          title: 'Title',
        },
        {
          name: 'log_changes',
          title: 'Log Changes',
          formatter: this.yesNo,
        },
        {
          name: 'is_required',
          title: 'Is Required',
          formatter: this.yesNo,
        },
        {
          name: 'has_many',
          title: 'Has Many',
          formatter: this.yesNo,
        },
        {
          name: 'disabled',
          title: 'Disabled',
          formatter: this.yesNo,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.propertiesURL(this.groupId)
    },
  },
  methods: {
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
  },
}
</script>
