<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Milestones"
  />
</template>

<script>
/**
 * View to list all Milestones for a Group.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      fields: [
        {
          name: 'type',
          title: 'Type',
          rowFormatter: this.typeToString,
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'required_states',
          title: 'Required States',
          formatter: this.listToString,
          visible: false,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.milestonesURL(this.groupId)
    },
  },
  methods: {
    listToString (l) {
      return l.join(', ')
    },
    typeToString (m) {
      if (m.is_check) {
        return 'CheckBox'
      } else if (m.is_date) {
        return 'Date'
      } else if (m.is_text) {
        return 'Text'
      } else if (m.attachment_type_id) {
        return 'Attachment'
      }
      return ''
    },
  },
}
</script>
