<template>
  <ListTemplate
    :fields="fields"
    :update-action="false"
    :api-url="apiURL"
    title="Users"
    create-title="Add"
  />
</template>

<script>
/**
 * View to display the users assigned to a user role.
 */
import ListTemplate from '@/components/ListTemplate'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
  },
  props: {
    /** group for role */
    groupId: {
      type: [String, Number],
      required: true,
    },
    roleId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      fields: [
        {
          name: 'name',
          title: 'Name',
          sortField: 'name',
        },
        {
          name: 'email',
          title: 'E-Mail',
          sortField: 'email',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return groupAPI.groupRoleUsersURL(this.groupId, this.roleId)
    },
  },
}
</script>
