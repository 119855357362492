<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="template.name"
      :maxlength="64"
      title="Name"
      name="name"
      type="text"
      placeholder="Template Name"
      required
      trim
    />
    <BaseSelect
      v-model="template.template_type"
      :options="templateTypes"
      title="Type"
      name="template_type"
      placeholder="Template Type"
      taggable
      required
      @tag="addTemplateType"
    />
    <BaseInput
      v-model="template.subject"
      title="Subject"
      name="subject"
      type="text"
      placeholder="Subject"
      required
      trim
    />
    <BaseTextarea
      v-model="template.body"
      :rows="10"
      title="Body"
      name="body"
      required
    />
    <BaseInputCheckbox
      v-model="template.is_html"
      title="Is HTML"
      name="is_html"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit or update an email template for a group
*/
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    /** group for email template to edit/create */
    groupId: {
      type: [String, Number],
      required: true,
    },
    /** if set, email template to edit */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      template: {
        name: '',
        subject: '',
        body: '',
        template_type: 'State Change',
        is_html: false,
      },
      templateTypes: [
        'State Change',
        'Update Notification',
        'Property Change',
        'Project Change',
        'Role Update Notification',
        'Team Change',
        'OnHold Change',
        'Booking Review Notification',
        'Booking Status Change',
        'Booking Create Notification',
        'Booking - Empty CR Warning',
        'Booking - Empty CR Cancel',
        'Post CR Report',
      ],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create Email Template'
      }
      return 'Update Email Template'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    submit () {
      if (this.id) {
        this.updateTemplate()
      } else {
        this.createTemplate()
      }
    },
    createTemplate () {
      this.error = ''
      groupAPI.createEmailTemplate(this.groupId, this.template).then(resp => {
        this.$parent.$toasted.success('Template ' + this.template.name + ' created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    updateTemplate () {
      this.error = ''
      groupAPI.updateEmailTemplate(this.groupId, this.template).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      if (!this.id) {
        return
      }
      groupAPI.getEmailTemplate(this.groupId, this.id).then(resp => {
        this.template = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    addTemplateType (t) {
      this.templateTypes.push(t)
      this.template.template_type = t
    },
  },
}
</script>
