<template>
  <BaseFormCard
    :heading="title"
    :submit-title="title"
    @submit="save"
  >
    <BaseInput
      v-model="group.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="group.descr"
      title="Description"
      name="descr"
      type="text"
      placeholder="Description"
      trim
    />
    <BaseInputCheckbox
      v-model="group.use_subcats"
      title="Use Subcategories"
      name="use_subcats"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Create or update a group
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'
import { mapActions } from 'vuex'

export default {
  components: {
    FieldError,
  },
  props: {
    /**
     * Group to edit. If not provided, create a new group.
     */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      group: {
        id: '',
        name: '',
        descr: '',
        use_subcats: false,
      },
      error: '',
    }
  },
  computed: {
    title () {
      if (this.id) {
        return 'Update Delivery Group'
      }
      return 'Create Delivery Group'
    },
  },
  beforeMount () {
    if (this.id) {
      this.getGroup(this.id).then(group => {
        this.group = Object.assign({}, group)
      }).catch(error => {
        this.error = error.message
      })
    }
  },
  methods: {
    save () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create () {
      const group = { name: this.group.name, descr: this.group.descr }
      groupAPI.createGroup(group).then(resp => {
        this.$parent.$toasted.success('Delivery Group ' + this.group.name + ' created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      groupAPI.updateGroup(this.group).then(resp => {
        this.$parent.$toasted.success('Delivery Group ' + this.group.name + ' updated')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('group', ['getGroup']),
  },
}
</script>
