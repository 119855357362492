<template>
  <BaseFormCard
    :heading="submitName + ' Service Group'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="serviceGroup.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="serviceGroup.descr"
      title="Description"
      name="descr"
      type="text"
      placeholder="Description"
      required
      trim
    />
    <BaseInputNumber
      v-model.number="serviceGroup.max"
      title="Maximum Count"
      name="max"
    />
    <BaseInput
      v-model="serviceGroup.platform"
      title="Platform"
      name="platform"
      type="text"
      placeholder="Platform"
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      serviceGroup: {
        name: '',
        descr: '',
        max: null,
        platform: null,
      },
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    if (this.id) {
      this.getServiceGroup()
    }
  },
  methods: {
    getServiceGroup () {
      groupAPI.getServiceGroup(this.groupId, this.id).then(resp => {
        this.serviceGroup = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    create () {
      groupAPI.createServiceGroup(this.groupId, this.serviceGroup).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    update () {
      groupAPI.updateServiceGroup(this.groupId, this.serviceGroup).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
  },
}
</script>
