<template>
  <BaseFormCard
    heading="Create Change Notification"
    submit-title="Create Change Notification"
    @submit="create"
  >
    <BaseSelect
      v-model="n.method"
      :options="methods"
      title="Method"
      name="method"
      required
    />
    <BaseSelect
      v-model="n.resource"
      :options="resources"
      title="Resource"
      name="resource"
      required
    />
    <BaseSelect
      v-model="n.email_template"
      :options="templates"
      title="Email Template"
      name="email_template"
      track-by="id"
      label="name"
      placeholder=""
      required
    />
    <BaseSelect
      v-model="n.usr_role"
      :options="roles"
      title="Roles"
      name="usr_role"
      track-by="id"
      label="name"
      placeholder="Select Role"
      required
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View for editing/creating an Email notification (project state email).
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    // group for notification
    groupId: {
      type: [String, Number],
      required: true,
    },
    pseId: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      n: {
        method: '',
        resource: '',
        email_template: '',
        usr_role: '',
      },
      error: '',
      roles: [],
      templates: [],
      methods: ['GET', 'PUT', 'DELETE', 'POST'],
      resources: ['aims', 'attachment', 'attachments', 'bookings', 'delivery_date', 'financials', 'milestone', 'milestones', 'project', 'platforms', 'quotes', 'requesting_group', 'roles', 'sites', 'team', 'timeline', 'update'],
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    create () {
      this.error = ''
      groupAPI.createProjectChangeEmail(this.groupId, this.n).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    getData () {
      const requests = [
        groupAPI.getGroupRoles(this.groupId),
        groupAPI.getEmailTemplates(this.groupId),
      ]
      Promise.all(requests).then(([roles, templates]) => {
        this.roles = roles.data
        this.templates = templates.data
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
