<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiURL"
    title="Services"
    default-sort="service_group.name,name"
  >
    <template #icon="props">
      <img
        v-if="props.rowData.service_icon_id"
        :src="iconURL(props.rowData.service_icon_id)"
        width="42"
        class="mr-3 bg-white rounded-pill"
      >
    </template>
    <template #colour="props">
      <span
        v-if="props.rowData.colour"
        style="width: 20px; height: 20px; display: inline-block;"
        :style="{ 'background-color': props.rowData.colour }"
      />
    </template>
    <template
      #price="props"
      class="p-0"
    >
      <div
        v-if="!props.rowData.prices"
        style="padding: 6px 10px;"
      >
        {{ props.rowData.price }}
      </div>
      <table
        v-else
        class="w-100"
      >
        <tr v-if="props.rowData.price !== undefined && props.rowData.price !== null">
          <td class="border-bottom-0">
            Default
          </td>
          <td class="border-bottom-0">
            {{ props.rowData.price }}
          </td>
        </tr>
        <tr
          v-for="p in props.rowData.prices"
          :key="p.min"
        >
          <td class="border-bottom-0">
            &gt;={{ p.min }}
          </td>
          <td class="border-bottom-0">
            {{ p.price }}
          </td>
        </tr>
      </table>
    </template>
    <template #actions="props">
      <router-link
        :to="{ name: 'service_attachment_list', params: { serviceId: props.rowData.id } }"
        class="btn btn-icon btn-primary"
        tag="button"
        append
      >
        <FileMultipleOutlineIcon title="Required Attachments" />
      </router-link>
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to display an email template
 */
import ListTemplate from '@/components/ListTemplate'
import FileMultipleOutlineIcon from 'vue-material-design-icons/FileMultipleOutline.vue'
import groupAPI from '@/api/group'

export default {
  components: {
    ListTemplate,
    FileMultipleOutlineIcon,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'service_group_name',
          title: 'Group',
          dataClass: 'text-nowrap',
          // sortField: 'service_group.name',
          filterType: String,
          filterField: 'service_group.name_like',
        },
        {
          name: 'name',
          title: 'Name',
          dataClass: 'text-nowrap',
          sortField: 'name',
          filterType: String,
          filterField: 'name_like',
        },
        {
          name: 'descr',
          title: 'Description',
        },
        {
          name: 'price',
          title: 'Price',
          dataClass: 'p-0',
        },
        {
          name: 'slo',
          title: 'SLO (days)',
        },
        {
          name: 'max',
          title: 'Max',
        },
        {
          name: 'icon',
          title: 'Icon',
        },
        {
          name: 'colour',
          title: 'Colour',
          dataClass: 'center aligned',
        },
        {
          name: 'is_default',
          title: 'Is Default',
          dataClass: 'center aligned',
          formatter: this.yesNo,
        },
        {
          name: 'team_name',
          title: 'Team',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'text-nowrap',
        },
      ],
      error: '',
    }
  },
  computed: {
    apiURL () {
      return groupAPI.servicesURL(this.groupId)
    },
  },
  methods: {
    iconURL (id) {
      return '/api' + groupAPI.serviceIconURL(this.groupId, id)
    },
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
  },
}
</script>
