<template>
  <BaseFormCard
    :heading="submitName + ' Group Role'"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="userRole.name"
      :maxlength="32"
      title="Name"
      name="name"
      type="text"
      placeholder="Name"
      required
      trim
    />
    <BaseInput
      v-model="userRole.descr"
      :maxlength="32"
      title="Description"
      name="descr"
      type="text"
      placeholder="Description"
      trim
    />
    <BaseInput
      v-model="userRole.info"
      title="Info"
      name="info"
      type="text"
      placeholder="Info"
      trim
    />
    <BaseInputCheckbox
      v-model="userRole.is_project_role"
      :disabled="!!id || userRole.is_team_role"
      title="Project Role"
      name="is_project_role"
    />
    <BaseInputCheckbox
      v-model="userRole.is_team_role"
      :disabled="!!id || userRole.is_project_role"
      title="Team Role"
      name="is_team_role"
    />
    <BaseInputCheckbox
      v-model="userRole.is_team_usr"
      :disabled="!!id || !userRole.is_project_role"
      title="Team User"
      name="is_team_usr"
    />
    <BaseInputCheckbox
      v-model="userRole.is_creator"
      :disabled="!!id || !userRole.is_project_role"
      title="Initialised with Project Creator"
      name="is_creator"
    />
    <BaseInputCheckbox
      v-model="userRole.allow_project_update_email"
      title="Allow Project Update Emails"
      name="allow_project_update_email"
    />
    <BaseSelect
      v-model="userRole.required_state"
      :options="states"
      :disabled="!userRole.is_project_role"
      title="Required State"
      name="required_state"
      track-by="id"
      label="name"
    />
    <BaseSelect
      v-model="userRole.notifiy_roles"
      :options="roles"
      :disabled="!userRole.is_project_role"
      title="Notify Roles On Update"
      name="notifiy_roles"
      multiple
    />
    <BaseInput
      v-model="userRole.ldap_group_dn"
      :disabled="userRole.is_project_role || userRole.is_team_role"
      title="LDAP Group DN"
      name="ldap_group_dn"
      type="text"
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * Modal component to edit or create a group role
 */
import FieldError from '@/components/FieldError'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
  },
  props: {
    // group for role
    groupId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      userRole: {
        name: '',
        descr: '',
        info: null,
        is_project_role: false,
        is_team_role: false,
        is_team_usr: false,
        is_creator: false,
        allow_project_update_email: false,
        required_state: null,
        ldap_group_dn: null,
        notifiy_roles: [],
      },
      states: [],
      roles: [],
      error: '',
    }
  },
  computed: {
    submitName () {
      if (this.id) {
        return 'Update'
      }
      return 'Create'
    },
  },
  beforeMount () {
    Promise.all([
      groupAPI.getProjectStates(this.groupId),
      groupAPI.getGroupRoles(this.groupId),
    ]).then(([states, roles]) => {
      this.states = states.data
      this.roles = roles.data.map(r => r.name)
    }).catch(error => {
      this.error = error.message
    })
    if (this.id) {
      groupAPI.getGroupRole(this.id).then(resp => {
        this.userRole = resp.data
      }).catch(error => {
        this.error = error.message
      })
    }
  },
  methods: {
    submit () {
      if (this.id) {
        this.updateUserRole()
      } else {
        this.createUserRole()
      }
    },
    createUserRole () {
      if (this.userRole.is_project_role) {
        this.userRole.is_team_role = false
      }
      if (this.userRole.is_project_role || this.userRole.is_team_role) {
        this.userRole.ldap_group_dn = null
      }
      if (!this.userRole.is_project_role) {
        this.userRole.is_team_usr = false
        this.userRole.is_creator = false
        this.userRole.required_state = null
      }
      groupAPI.createGroupRole(this.groupId, this.userRole).then(resp => {
        this.$parent.$toasted.success('Role created')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    updateUserRole () {
      groupAPI.updateGroupRole(this.userRole).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
